// coming soon 2 start
.landing-wrap2 {
  @extend .landing-wrap;
  overflow: hidden;
}

.landing-scene-2 {
  @extend .landing-scene-1;
  background: url(../images/scene-2.png) no-repeat center bottom #fff9ed;
  // background-size: cover;
}

@media screen and (min-width: 1200px) {
  .landing-wrap2 {
    min-height: 1080px;
  }
}

@media screen and (min-width: 600px) and (min-width: 900px) {
  .landing-wrap2 {
    min-height: 800px;
  }
}

.charecter-2 {
  position: absolute;
  z-index: 130;
  bottom: 0;
  width: 480px;
  max-width: 545px;
  left: 50%;
  margin-left: -240px;
  img {
    max-width: 100%;
    height: auto;
  }
  @media screen and (min-width: 768px) {
    width: 545px;
    left: 50%;
    margin-left: -272.5px;
    bottom: 0px;
  }
  @media screen and (min-width: 1200px) {
    bottom: 85px;
  }
}

.landing-message2 {
  @extend .landing-message;
  h1 {
    color: #6f7071;
  }
}

.egg {
  position: absolute;
  left: 0;
  bottom: 48px;
  width: 12.110091743119266%;
  left: 43.48623853211009%;
  transform-origin: center 80%;
  animation: nest 2s linear 2s infinite;
  will-change: transform;
}

.nest-part-2 {
  width: 43.11926605504588%;
  position: absolute;
  left: 28.073394495412845%;
  bottom: 1px;
}

@keyframes nest {
  0% {
    transform: rotate(0);
  }
  10% {
    transform: rotate(10deg);
  }
  20% {
    transform: rotate(-10deg);
  }
  30% {
    transform: rotate(0);
  }
  50% {
    transform: rotate(5deg);
  }
  70% {
    transform: rotate(-5deg);
  }
  100% {
    transform: rotate(0);
  }
}


@media screen and (max-width: 767px) and (max-height: 700px) {
  .landing-wrap2 {
    min-height: 500px;
  }
}

@media screen and (max-height: 800px) {
  .landing-wrap2 {
    min-height: 800px;
  }
}

@media screen and (max-width: 768px) {
  .charecter-2 {
    width: 90%;
    margin-left: -45%;
  }
  .egg {
    bottom: 10%;
  }
}

.landing-2 {
  min-height: 1300px;
  @include respond-to("sm") {
    min-height: 1300px;
  }

  @include respond-to("md") {
    min-height: 1400px;
  }

  @include respond-to("lg") {
    min-height: 1600px;
  }
}

@media screen and (max-width: 480px) {
  .landing-scene-2 {
    background-size: 270%;
  }
}

// coming soon 2 end