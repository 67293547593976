// coming soon 1 start
.landing-scene-1 {
  position: absolute;
  width: 100%;
  height: 100%;
  left: 0;
  top: 0;
  z-index: 10;
  background: url(../images/scene-1.png) no-repeat left bottom #A3E7FF;
  background-size: 100%;
  @media screen and (min-width: 1200px) {
    min-width: 1200px;
  }
}

.landing-scene-1-charecter {
  position: absolute;
  left: 0;
  bottom: 0;
  width: 100%;
  background-size: 100%;
  z-index: 130;
  & > img {
    max-width: 100%;
  }
}

.drops {
  width: 5.631%;
  position: absolute;
  left: 19.00544%;
  top: 6%;
  z-index: 150;
  img {
    max-width: 100%;
    height: auto;
  }
}

.drop {
  position: absolute;
  top: 0;
  width: percentage(9 / 107);
}

.drop1 {
  left: 20%;
  animation: drop1 4s linear 2s infinite;
}

.drop2 {
  left: 45%;
  top: 5%;
  animation: drop1 4s linear 0s infinite;
}

.drop3 {
  left: 70%;
  top: -5%;
  animation: drop1 5s linear 0s infinite;
  width: percentage(5 / 107);
  transform: rotate(5deg);
}

.drop4 {
  right: 10%;
  top: -5%;
  animation: drop1 4s linear 6s infinite;
  width: percentage(6 / 107);
  transform: rotate(5deg);
}

@keyframes drop1 {
  100% {
    opacity: 0;
    top: 30%;
  }
}


@keyframes drop1 {
  100% {
    opacity: 0;
    top: 30%;
  }
}


.landing-wrap {
  position: relative;
  height: 100%;
  overflow: hidden;
}

.sun {
  width: 50px;
  height: 50px;
  background: #fdffb5;
  border-radius: 50%;
  position: absolute;
  right: 10%;
  top: 30px;
  box-shadow: 0px 0px 60px 30px #f0ffc8;
  z-index: 19;
  animation-name: sun;
  animation-delay: 2s;
  animation-duration: 60s;
  will-change: transform;
  animation-iteration-count: infinite;
  @media screen and (min-width: 1200px) {
    width: 143px;
    height: 143px;
    top: 230px;
    width: 100px;
    height: 100px;
  }
}

.landing-message {
  @extend .text-center;
  h1 {
    font-size: 40px;
    font-weight: 700;
    font-family: 'Open Sans', sans-serif;
    color: #558b9f;
    margin-bottom: 20px;
    @include respond-to("sm") {
      font-size: 60px;
    }

    @include respond-to("md") {
      font-size: 80px;
    }
  }
  p {
    font-size: 14px;
    line-height: 21px;
    color: #558b9f;
    font-family: 'Open Sans', sans-serif;
    padding: 0 20px;
  }
  .large-messge {
    display: block;
    margin: 0 auto;
  }
  @media screen and (min-width: 768px) {
    top: 50px;
  }
  @media screen and (min-width: 1200px) {
    top: 155px;
    .large-messge {
      max-width: 620px;
    }
    h1 {
      font-size: 93px;
    }
    p {
      margin-top: 40px;
    }
  }
}

.cloudWrapper {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  z-index: 20;
  overflow: hidden;
}

.cloud {
  position: absolute;
  z-index: 121;
  will-change: transform;
}

.cloud-1 {
  width: percentage(320 / 1900);
  top: 50px;
  animation: cloud 20s linear infinite;
  left: -(percentage(320 / 1900));
}

.cloud-2 {
  width: percentage(517 / 1900);
  top: 250px;
  animation: cloud 38s linear infinite;
  left: -(percentage(517 / 1900));
}

.cloud-3 {
  width: percentage(466 / 1900);
  top: 150px;
  animation: cloud 27s linear 6s infinite;
  left: -(percentage(466 / 1900));
}

.cloud-4 {
  width: percentage(508 / 1900);
  top: 10%;
  animation: cloud 40s linear 4s infinite;
  left: -(percentage(508 / 1900));
}

.cloud {
  background-repeat: no-repeat;
  background-size: 100%;
}

@keyframes cloud {
  from {
    transform: translateX(0%);
  }
  to {
    transform: translateX(700%);
  }
}


@media screen and (max-width: 1024px) {
  @keyframes cloud {
    from {
      transform: translateX(0%);
    }
    to {
      transform: translateX(900%);
    }
  }
}

@keyframes sun {
  50% {
    box-shadow: 0px 0px 20px 10px #f0ffc8;
  }
  100% {
    box-shadow: 0px 0px 60px 30px #f0ffc8;
  }
}


.landing-1 {
  min-height: 1200px;
  @include respond-to("sm") {
    min-height: 950px;
  }

  @include respond-to("md") {
    min-height: 1200px;
  }

  @include respond-to("lg") {
    min-height: 1500px;
  }
}

// coming soon 1 end