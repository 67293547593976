// nav
.page-wrap {
  .logo {
    margin-top: 55px;
  }
  text-align: center;
  position: absolute;
  z-index: 200;
  width: 100%;
}

.mainnav {
  margin-top: 25px;
  ul {
    display: inline-table;
    @extend .nav;
    @extend .nav-pills;
    li {
      & + li {
        margin-left: 10px;
      }
      a {
        padding: 6px 7px;
        font-size: 15px;
        background: none;
        color: #558b9f;
        border: 1px solid #558b9f;
        transition: background 1s ease;
        @include respond-to("sm") {
          padding: 12px 17px;
        }
      }
      a.active, a:hover, a:focus {
        color: #fff;
        background: #558b9f;
      }
    }
  }
}

.countdown {
  margin-top: 50px;
  .dash {
    float: left;
    margin-left: 25px;
    margin-right: 25px;
    .digit {
      float: left;
      font-size: 50px;
      color: #558b9f;
      font-weight: 300;
      min-height: 80px;
      font-weight: 300;
      font-family: 'Open Sans', sans-serif;
      @include respond-to("sm") {
        font-size: 80px;
        min-height: 90px;
      }

      @include respond-to("md") {
        font-size: 100px;
        min-height: 145px;
      }

      @include respond-to("lg") {
        font-size: 150px;
        min-height: 225px;
      }
    }
    .dash_title {
      display: block;
      clear: both;
      font-size: 15px;
      font-weight: 300;
      color: #558b9f;
      font-family: 'Open Sans', sans-serif;
      &:before {
        content: '';
        display: block;
        width: 100%;
        height: 1px;
        background: #eb7474;
        margin-bottom: 10px;
      }
      @include respond-to("md") {
        font-size: 25px;
      }
    }
  }
  .minutes_dash {
    clear: left;
    @include respond-to("sm") {
      clear: none;
    }
  }
  .countdown-cont {
    display: inline-table;
  }
}

.page-content {
  display: none;
  padding: 20px 0;
  &.active {
    display: block;
  }
  h2 {
    font-size: 30px;
    color: #558b9f;
    font-weight: 700;
    margin-bottom: 25px;
    @include respond-to(md) {
      font-size: 50px;
    }
  }
}

.footer {
  position: absolute;
  z-index: 200;
  width: 100%;
  text-align: center;
  bottom: 1%;
  @include respond-to("md") {
    bottom: 5%;
  }

  p {
    color: #6e6f71;
  }
}

.subscribe-form {
  width: 100%;
  float: left;
  margin-top: 50px;
  text-align: center;
  form {
    display: block;
    overflow: hidden;
    margin: 0 auto;
    width: 100%;
  }
  .form-control {
    width: 100%;
    margin-bottom: 15px;
    min-height: 42px;
    border-color: #558b9f;
    border-radius: 0;
    background: rgba(255, 255, 255, 0.5);
  }
  @include respond-to("xs") {
    form {
      width: 400px;
      position: relative;
      padding-right: 100px;
    }
    .form-control {
      margin-bottom: 0;
      border-color: #aefcfc;
    }
    .btn {
      position: absolute;
      right: 0;
      top: 0;
      padding: 10px 12px;
      border-radius: 0;
    }
  }
}

.btn {
  @include button-variant(#fff, #558b9f, #558b9f);
}

.page-content {
  p {
    color: #68a5bc;
    font-size: 14px;
    line-height: 26px;
  }
  article {
    @include make-sm-column(6);
    text-align: left;
    p {
      color: #558b9f;
    }
  }
}

.contact-form {
  margin-top: 30px;
  @include respond-to("md") {
    width: 100%;
    float: left;
  }

  .form-control {
    border-color: #aefcfc;
    min-height: 40px;
    box-shadow: none;
    border-radius: 0;
    background: rgba(255, 255, 255, 0.3);
  }
  @include make-row;
  .input-1,
  .input-2,
  .input-3 {
    @include make-md-column(4);
  }
  .input-4 {
    @include make-md-column(12);
    textarea {
      min-height: 70px;
    }
  }
  .form-submit {
    @include make-md-column(12);
    text-align: center;
  }
  .btn {
    padding: 12px 25px;
    text-transform: uppercase;
    font-size: 20px;
    border-radius: 0;
  }
}

//
.landing-2 {
  .page-content {
    .btn {
      @include button-variant(#fff, #6e6f71, #6e6f71);
    }
    h1, h2, p, .digit, .dash_title {
      color: #6e6f71 !important;
    }
  }
  .mainnav ul li {
    a {
      color: #6e6f71;
      border-color: #c6c6c6;
    }
    a:hover, a:focus, a.active {
      background: #6e6f71;
      color: #fff;
    }
  }
  .form-control {
    @include form-control-focus(#ccc);
    border-color: #c6c6c6;
  }
}

.form-control.error {
  border-color: red !important;
}

#subscribe {
  p {
    margin-top: 30px;
  }
}

@keyframes slideInUp {
  0% {
    transform: translate3d(0, 20px, 0);
    visibility: visible;
  }
  100% {
    transform: translate3d(0, 0, 0);
  }
}


.slideInUp1 {
  animation-name: slideInUp;
}